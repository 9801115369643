/* -------------------------------------------------------------------------- */
/*                             Media queries mixins                           */
/* -------------------------------------------------------------------------- */

// Breakpoints
$breakpoints: (
  landscape-mobile: '(orientation: landscape) and (max-height: 688px)',
  small-mobile: '(max-width: 350px) and (max-height: 650px)',
  very-small-mobile: '(max-width: 320px) and (max-height: 600px)',
  medium-mobile: '(min-width: 376px)',
  tablet: '(min-width: 689px)',
  desktop: '(min-width: 993px)',
  small-desktop: '(min-width: 993px)',
  medium-desktop: '(min-width: 1450px)',
  large-desktop: '(min-width: 1700px)',
  mobil-h: '(min-width:350px)and (max-height: 800px)',
);

@mixin device($selected-device) {
  @each $device-name, $size in $breakpoints {
    @if $selected-device==$device-name {
      @media #{$size} {
        @content;
      }
    }
  }
}
